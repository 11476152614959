import { FC, memo, useCallback } from "react";
import { useHistory } from "react-router";

import { Classes, SimpleFn } from "web/types/Common";

import classify from "web/classify";
import { useAppContext } from "web/context/app";

import { IsThankYouPage, Link, Pathname, Search } from "../../Types/types";
import NavigationItem from "./NavigationItem";
import defaultClasses from "./navigationList.scss";

interface ICheckoutNavigationList {
  links: Link[];
  pathname: Pathname;
  search: Search;
  classes: Classes;
  listItemClasses?: Classes;
  isThankYouPage?: IsThankYouPage;
  onClick?: SimpleFn;
}

const CheckoutNavigationList: FC<ICheckoutNavigationList> = memo(
  ({
    links,
    pathname,
    search,
    classes,
    listItemClasses = {},
    isThankYouPage = false,
    onClick,
  }) => {
    const history = useHistory();
    const { isMobile } = useAppContext();

    const { key: activeStepKey } =
      links.find(({ link }) => link === `${pathname}${search}`) || {};

    const onClickHandler = useCallback(
      (targetLink: string) => {
        if (typeof onClick === "function") onClick();
        if (activeStepKey && targetLink)
          history.push(targetLink);
      },
      [onClick, activeStepKey]
    );

    if (!activeStepKey) return null;

    return (
      <div className={classes.nav}>
        {links.map(({ link, label, key }, index) => {
          const disabled = isThankYouPage ? false : activeStepKey > key;
          const active = isThankYouPage ? true : activeStepKey === key;

          return (
            <span
              key={key}
              className={classes.item}
              data-t2={link}
              data-t1="navigation_list_item"
              data-testid="navigation_list_item"
            >
              <NavigationItem
                label={isMobile ? key : label}
                disabled={disabled}
                active={active}
                onClick={() => onClickHandler(link)}
                classes={listItemClasses}
                isThankYouPageHelper={
                  isThankYouPage && links.length - 1 !== index
                }
              />
            </span>
          );
        })}
      </div>
    );
  }
);

export default classify<ICheckoutNavigationList>(defaultClasses)(
  CheckoutNavigationList
);
