/* eslint-disable @typescript-eslint/ban-ts-comment */
import DOMPurify from "dompurify";
import { FC, useEffect } from "react";
import { useDispatch } from "react-redux";

import ErrorBoundary from "web/Layout/ErrorBoundary";

import useHTMLLink from "web/hooks/useHTMLLink";

import jsonParse from "web/utils/data/parser/string/jsonParse";

import { IGtmEcommerceImpressionPromotions, IPromoItems } from "web/types/Gtm";
import { IParsedParameters } from "web/types/PageGenerator";
import { Nullable } from "web/types/Utils";

import { setPromoLinks } from "web/features/gtm/gtm";

import { IPromoCounter } from "../pageGeneratorQuery";
import {
  createGtmClickProps,
  createGtmImpressionProps,
  modifyImage,
} from "../utils/gtmPromoEvent";
import classes from "./html.scss";

interface IPageGeneratorHtmlProps {
  params: string;
  isMobile: boolean;
  promoItem: Nullable<IPromoCounter>;
}

const PageGeneratorHtml: FC<IPageGeneratorHtmlProps> = ({
  params,
  isMobile,
  promoItem,
}) => {
  const dispatch = useDispatch();
  const data: IParsedParameters = jsonParse(params);
  const contentRef = useHTMLLink();

  useEffect(() => {
    if (!contentRef?.current || !promoItem) return;

    const images = contentRef.current.getElementsByTagName("img");
    const gtmOptions = Object.values(images).map((image, index) => {
      const name = modifyImage(image.src);
      const link = image.closest("a")?.getAttribute("href") || "";
      const options: IGtmEcommerceImpressionPromotions = {
        name,
        position: index + promoItem?.promoPosition,
        // @ts-ignore
        promotion_name: name,
        creative_slot: index + promoItem?.promoPosition,
      };

      image.setAttribute("data-promo-position", `${options.position}`);
      image.setAttribute("data-promo-src", options.name);
      image.setAttribute("data-link", link);
      image.addEventListener("click", onContainerClick);
      return options;
    });

    if (gtmOptions) {
      createGtmImpressionProps(gtmOptions);
    }
    // eslint-disable-next-line consistent-return
    return () =>
      Object.values(images).forEach((image) =>
        image.removeEventListener("click", onContainerClick)
      );
  }, [contentRef, JSON.stringify(promoItem)]);

  const onContainerClick = (e: MouseEvent) => {
    const {
      promoPosition: position,
      promoSrc: name,
      link,
    } = (e.target as HTMLButtonElement).dataset;
    if (!position || !name) return;

    if (link) {
      const promotionsPerProduct: IPromoItems = {};
      promotionsPerProduct[link] = {
        creative_slot: +position,
        promotion_name: name,
      };
      dispatch(setPromoLinks(promotionsPerProduct));
    }

    createGtmClickProps([
      {
        name,
        // @ts-ignore
        position,
        promotion_name: name,
        creative_slot: position,
      },
    ]);
  };

  const style = isMobile
    ? {
        marginTop: "0",
        marginBottom: "0",
      }
    : {
        marginTop: "60px",
        marginBottom: "60px",
      };

  return data?.content ? (
    <div className="container">
      <div
        data-t1="pageGeneratorHtml"
        className={`${classes.containerBox}`}
        style={style}
        // eslint-disable-next-line
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(data.content as string),
        }}
        // @ts-ignore
        ref={contentRef}
      />
    </div>
  ) : null;
};

interface IPageGeneratorHtmlContainerErrorProps {
  params: string;
  isMobile?: boolean;
  promoItem?: IPromoCounter;
}

const PageGeneratorHtmlContainerError: FC<
  IPageGeneratorHtmlContainerErrorProps
> = ({ isMobile = false, params, promoItem = null }) => {
  return (
    <ErrorBoundary>
      <PageGeneratorHtml
        isMobile={isMobile}
        params={params}
        promoItem={promoItem}
      />
    </ErrorBoundary>
  );
};

export default PageGeneratorHtmlContainerError;
