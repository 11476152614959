import { FC } from "react";

import __ from "web/Layout/Translations";

import ExternalLink from "web/Components/Common/ExternalLink/externalLink";

import EnvelopeIcon from "web/assets/icons/envelope-closed.svg";
import TelephoneIcon from "web/assets/icons/telephone.svg";
import WwwIcon from "web/assets/icons/www.svg";

import type { Email, Nullable } from "web/types/Utils";

import Section from "../../Section";
import classes from "./contacts.scss";

interface ICheckoutTourismDomesticContactsDesktopProps {
  phone: Nullable<string>;
  email: Nullable<Email>;
  site: Nullable<string>;
}

const CheckoutTourismDomesticContactsDesktop: FC<
  ICheckoutTourismDomesticContactsDesktopProps
> = ({ phone = "", email = "", site = "" }) => {
  return phone || email || site ? (
    <Section title={__("Kontakt do obiektu")}>
      <div className={classes.root}>
        {phone ? (
          <div className={classes.item}>
            <TelephoneIcon className={classes.iconPhone} />
            <a className={classes.link} href={`tel:${phone}`} title={phone}>
              {phone}
            </a>
          </div>
        ) : null}
        {email ? (
          <div className={classes.item}>
            <EnvelopeIcon className={classes.iconEmail} />
            <a className={classes.link} href={`mailto:${email}`} title={email}>
              {email}
            </a>
          </div>
        ) : null}
        {site ? (
          <div className={classes.item}>
            <WwwIcon className={classes.iconLink} />
            <ExternalLink className={classes.link} url={site} />
          </div>
        ) : null}
      </div>
    </Section>
  ) : null;
};

export default CheckoutTourismDomesticContactsDesktop;
