import { motion } from "framer-motion";

import PointsCommon from "web/Layout/Common/Points";

import ArrowDownIcon from "web/assets/icons/arrow-down.svg";

import useDropdown from "web/hooks/useDropdown";

import { useGetBanksQuery } from "web/features/pointsBank/pointsBankApiSlice";

import classes from "./points.scss";

const dropdownVariants = {
  open: {
    height: "auto",
  },
  closed: {
    height: 0,
  },
};
const transition = { duration: 0.25 };

const Points = () => {
  const { data: dataBanks } = useGetBanksQuery();
  const { itemsActive: banks, total } = dataBanks || {};

  const [isOpen, setIsOpen] = useDropdown({
    scopeSelector: `.${classes.root}`,
  });
  const iconClassName = isOpen ? classes.iconOpened : classes.icon;
  return (
    <div className={classes.root} data-testid="checkout_desktop_header_points">
      <button
        className={classes.button}
        type="button"
        onClick={() => setIsOpen((state) => !state)}
        disabled={!total}
        data-t1="checkout_points_button"
      >
        <ArrowDownIcon className={iconClassName} />
        <PointsCommon className={classes.points} points={total} />
      </button>
      <div className={classes.body}>
        <motion.div
          className={classes.dropdown}
          initial="closed"
          animate={isOpen ? "open" : "closed"}
          transition={transition}
          variants={dropdownVariants}
          data-t1="checkout_points_dropdown"
        >
          <div className={classes.wrapper}>
            <ul className={classes.list}>
              {banks?.map(
                ({
                  balance,
                  points_bank_id: bankId,
                  points_bank_name: name,
                }) => (
                  <li
                    className={classes.item}
                    key={bankId}
                    data-t1="checkout_points_item"
                    data-t2={bankId}
                  >
                    <span
                      className={classes.itemLabel}
                      data-t1="checkout_points_item_name"
                    >
                      {name}
                    </span>
                    <div className={classes.itemRow}>
                      <span className={classes.itemPlus} />
                      <PointsCommon
                        className={classes.itemPoints}
                        points={balance}
                      />
                    </div>
                  </li>
                )
              )}
            </ul>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Points;
